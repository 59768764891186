<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          岗位评聘系统
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">系统功能图</h4>
        <span class="line"></span>
         <img src="@/assets/product/position/gnt.png" alt="" style="width:1000px;margin-top:40px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">系统价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <div class="valueTitle">
              {{item.title}}
            </div>
            <div class="valueLine"></div>
            <div class="valueText">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductPosition',
  data () {
    return {
      valueList: [
        { title: '评聘方案自定义', content: '自定义评聘方案，实现岗位聘任、等级晋升、职称评审、聘期考核等多业务评聘自主化。' },
        { title: '健全流程设计', content: '线上申报到资格审核、材料审核，全流程环环相扣，评聘规范化、标准化。' },
        { title: '跨部门数据共享', content: '业绩数据自动获取，实现数据“一次采集，重复使用，自动填报”，提高材料申报和审核工作效率。' },
        { title: '申报资格智能判断', content: '根据职工基本信息、申报材料智能判断是否符合申报条件，申报人数自动摸底。' }
      ],
      mainFunction: [
        {
          title: '岗位评聘系统',
          fontLeft: false,
          p: [
            {
              desc: '岗位评聘系统是对岗位聘任、职称评审、聘期考核业务的综合管理，基于职工绩效数据，可实现申报信息自动抽取、评审材料电子化、评审过程网络化及流程化、评聘过程全留痕。'
            }
          ],
          src: require('@/assets/product/position/gaint.png')
        }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moPosition')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/position/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.mainFunction{
width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 60px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemArchitecture{
    width: 100%;
height: 670px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.systemValue{
     width: 100%;
height: 630px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  margin: 0px 20px 40px;
  height: 180px;
  border-radius: 10px;
  padding: 30px 40px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .valueTitle{
    font-weight: 500;
    font-size: 24px;
    color: #222222;
  }
  .valueLine{
    width: 30px;
    height: 4px;
    margin: 14px 0;
    background: #D70C0C;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #444;
  }
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueItem:nth-child(2n-1){
  margin-left: 0px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueText{
    color: #fff;
  }
}
</style>
